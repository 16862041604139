import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CancelOtpAPI, CheckOTPAPI, GenerateNumberAPI, GenerateOtpAPI } from "../utils/api";
import { FiCopy } from "react-icons/fi";
import Countdown from "react-countdown";
import { AppContext } from "../context/AppContext";

const initialTime = 20 * 60; // 20 minutes in seconds
const formatTime = time => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
const GetOtpCard = ({ Token, selectService, server, requestInProgress, setRequestInProgress, cancelBtnRequestInProgress, setCancelBtnRequestInProgress }) => {
  const inputRef = useRef(null);
  const { setBalance, handleLogout } = useContext(AppContext);
  const textAreaRef = useRef();
  const toast = useToast();
  const [remainingTime, setRemainingTime] = useState(initialTime);
  const [showTime, setShowTime] = useState(false);
  const intervalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [resend, setResend] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const requestRef = useRef(false);
  const [ws,setws]=useState(null);
  const [currentNumber, setCurrentNumber] = useState({
    serialNumber: "",
    mobileno: "",
    mobileId: "",
  });
  const serialNumberRef = useRef(null);
  const mobileIdRef = useRef(null);
  const CopyToClipBoard = () => {
    let numberForCopy = "";
    for (let i = 3; i < inputRef.current.value.length; i++) {
      numberForCopy += inputRef.current.value[i];
    }
    if (inputRef.current.value != "") {
      navigator.clipboard
        .writeText(numberForCopy)
        .then(() => {
          toast({
            status: "info",
            title: `${numberForCopy} number copied`,
            isClosable: true,
            duration: 1000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
          toast({
            status: "error",
            title: `errror in copying number ${inputRef.current.value}`,
            isClosable: true,
            duration: 1000,
          });
        });
    }
  };
  const handleOTP = (myResend = false,socket) => {
    if (serialNumberRef.current != ""&&socket) {
      // console.log('again',intervalRef.current);
      CheckOTPAPI(
        socket,
        {
          serialNumber: serialNumberRef.current,
          mobileId: mobileIdRef.current,
          businessCode: selectService.code,
          server,
          resend: myResend
        },
      )
        .then((res) => {
            requestRef.current = true;
            if (res && res.message == "OTP Successfully Generated!") {
              let otpMessage = res.otpMessage;
              if (textAreaRef.current) {
                textAreaRef.current.value = otpMessage;
              }
              // clearInterval(intervalRef.current);
              // if (intervalRef.current != null) {
                setResendLoading(false);
                toast({
                  title: "Otp fetched successfully",
                  description: "Now you can use this otp into your service",
                  status: "success",
                  isClosable: true,
                  duration: 2000,
                });
              // }
              // intervalRef.current = null;
              setShowTime(false);
              setResend(true);
            };
        })
        .catch((err) => {
          // clearInterval(intervalRef.current);
          // intervalRef.current = null;
          setResendLoading(false);
          console.log(err, "error saying");
          setShowTime(false);
          handleLogout(err);
        });
    }
  };
  const startChecking = (myResend = false) => {
    const socket = new WebSocket(
      `wss://test.techyindia.xyz/check-otp?token=${Token}`
    );
    // const min = 1000;
    // const max = 2000;
    // const random_number = Math.floor(Math.random() * (max - min + 1)) + min
    // requestRef.current = true;
    // if (intervalRef.current == null) {
      // intervalRef.current = setInterval(() => {
        // console.log(requestRef.current, 'requests');
        setTimeout(()=>{
          if (socket) {
            setws(socket);
            console.log("start checking for otp ....");
              handleOTP(myResend,socket);
            }
        },5000)
        // requestRef.current = false;
      // }, random_number);
    // }
  };
  const cancelBtn = () => {
    if (ws != null) {
      ws?.close();
      console.log("ws connection closed for ",currentNumber.mobileno);
      // inputRef.current.value = null;
     }
      toast({
        title: `Otp request canceled`,
        status: "warning",
        isClosable: true,
      });
      setCancelBtnRequestInProgress(true);
      // clearInterval(intervalRef.current);
      setShowTime(false);
      // intervalRef.current = null;
      setRemainingTime(initialTime);
      // if (server == 'server2' || server == 'server3') {
      setResend(false);
      setResendLoading(false);
      inputRef.current.value = null;
      CancelOtpAPI({
        serialNumber: serialNumberRef.current,
        server,
        mobileId: mobileIdRef.current,
      }, Token).then((res) => {
        setTimeout(() => {
          setCancelBtnRequestInProgress(false);
        }, 2000);
        setBalance(res.data.balance);
      })
        .catch((err) => {
          setCancelBtnRequestInProgress(false);
          console.log(err, "error saying");
          handleLogout(err);
        });
      // }
  };
  const handleNumber = () => {
    setLoading(true);
    setRequestInProgress(true);
    GenerateNumberAPI({ businessCode: selectService.code, server }, Token)
      .then((res) => {
        console.log(res.data,'checking my numbers here');
        setTimeout(() => {
          setLoading(false);
          setRequestInProgress(false)
        }, 2000);
        if (res.data?.mobile[0]?.mobileno?.length < 10 || res.data.mobile[0] == undefined) {
          toast({
            title: res.data.message,
            status: "info",
            isClosable: true,
            duration: 1500,
          });
          return;
        }
        setResend(false);
        setRemainingTime(initialTime);
        setExpired(false);
        if (Object.keys(res.data).length !== 0) {
          setBalance(res.data.balance);
          const serialNumber = res.data.mobile[0]?.serialNumber || "";
          const mobileno = res.data.mobile[0]?.mobileno || "";
          inputRef.current.value = mobileno;
          const mobileId = res.data.mobile[0]?._id || "";
          if (mobileId.length > 2) {
            setShowTime(true);
          }
          textAreaRef.current.value = "";
          setCurrentNumber({
            ...currentNumber,
            serialNumber,
            mobileId,
            mobileno,
          });
          serialNumberRef.current = serialNumber;
          mobileIdRef.current = mobileId;
          setTimeout(() => {
            startChecking();
          }, 500);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          setRequestInProgress(false)
        }, 2000);
        if (err.response?.data?.message === "User Doesn't have sufficient balance") {
          return;
        }
        setExpired(false);
        console.log(err, "error saying");
        setShowTime(false);
        handleLogout(err);
      });
  };
  useEffect(() => {
    if (remainingTime == 0) {
      setExpired(true);
      cancelBtn();
      setShowTime(false);
    }
    if (showTime && remainingTime > 0) {
      const intervalId = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 1);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [showTime, remainingTime]);
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    }
  }, [])
  return (
    <HStack
      p={['0 0', '0.1rem 0.1rem', "0.5rem 0.5rem"]}
      w={"100%"}
      gap={['0.3rem', '0.5rem', '0.8rem', "1rem"]}
      border={"1px solid transparent"}
      align={"start"}
    >
      <HStack gap={['0.2rem', '0.5rem', '1rem', '1rem']} flexDirection={["column", "column", "row", "row"]} >
        <Button
          type={"submit"}
          p={['1rem 1.6rem', '1rem 1.8rem', "1.1rem 2rem"]}
          w={['5.8rem', '7.5rem', '7.5rem', "7.5rem"]}
          _hover={{
            bgColor: "white",
            color: "rgb(220, 20, 60)",
          }}
          fontSize={['0.85rem', '0.9rem', '1rem', "1.1rem"]}
          color={"white"}
          bgColor={"rgb(220, 20, 60)"}
          border={"2px solid rgb(220, 20, 60) !important"}
          onClick={handleNumber}
          isDisabled={cancelBtnRequestInProgress || requestInProgress || loading}

        >
          {currentNumber.serialNumber ? "Buy Next" : "Get Number"}
        </Button>
        <Button
          type={"submit"}
          p={['1rem 1.6rem', '1rem 1.8rem', "1.1rem 2rem"]}
          _hover={{
            bgColor: "white",
            color: "rgb(220, 20, 60)",
          }}
          m={'0 !important'}
          w={['5.8rem', "7.5rem", 'auto', 'auto']}
          fontSize={['0.85rem', '0.9rem', '1rem', "1.1rem"]}
          color={"white"}
          bgColor={"rgb(220, 20, 60)"}
          border={"2px solid rgb(220, 20, 60) !important"}
          onClick={cancelBtn}
          isDisabled={requestInProgress || cancelBtnRequestInProgress}
        >
          Cancel
        </Button>
        {/* {(selectService.server == 'server2' || selectService.server == 'server3') &&  */}
        <Button
          p={['1rem 1.6rem', '1rem 1.8rem', "1.1rem 2rem"]}
          w={['5.8rem', '7.5rem', '7.5rem', "7.5rem"]}
          _hover={{
            bgColor: "white",
            color: "rgb(220, 20, 60)",
          }}
          m={'0 !important'}
          fontSize={['0.85rem', '0.9rem', '1rem', "1.1rem"]}
          color={"white"}
          bgColor={"rgb(220, 20, 60)"}
          border={"2px solid rgb(220, 20, 60) !important"}
          isDisabled={!resend || resendLoading}
          // loadingText={'resend'}
          // isLoading={resendLoading}
          onClick={() => {
            // setShowTime(true);
            setResendLoading(true);
            setTimeout(() => {
              startChecking(true);
            }, 500);
          }}
        >
          Resend Otp
        </Button>
      </HStack>
      <HStack flexDirection={["column", "column", "row", "row"]}
        gap={['0.4rem', '0.5rem', '0.8rem', "1rem"]}
        align={'start'} w={['100%', '100%', '85%', '75%']}>
        <InputGroup w={['80%', '90%', '90%', "90%"]}>
          <Input ref={inputRef} textAlign={['start', 'center', 'center', "center"]} readOnly />
          <InputRightElement>
            <Button
              bg={"white"}
              p={"0 !important"}
              h={"96%"}
              border={"1px solid gray !important"}
              color={"rgb(220, 20, 60)"}
              onClick={CopyToClipBoard}
            >
              <FiCopy />
            </Button>
          </InputRightElement>
        </InputGroup>
        <Textarea
          m={'0 !important'}
          ref={textAreaRef}
          readOnly={true}
          placeholder="Otp will be shown here"
          size={['sm', "sm", 'md', 'md']}
          w={['14rem', '18rem', '20rem', '21rem']}
        />
      </HStack>
      <HStack right={'1rem'} position={['absolute', 'initial', 'initial', 'initial']} w={['10%', '15%', '18%', "18%"]}>
        {
          expired && <Text fontSize={['0.8rem', '0.9rem', '1rem', "1rem"]}
            fontWeight={600}>
            Number expired
          </Text>
        }
        {showTime && <Text
          fontSize={['1rem', '1rem', '1.1rem', "1.2rem"]}
          color={"rgb(220, 20, 60)"}
          fontWeight={600}
          textAlign={"center"}
          mt={"0.8rem !important"}
        >
          {formatTime(remainingTime)}
        </Text>}
      </HStack>
    </HStack>
  );
};

export default GetOtpCard;